<template>
  <div>
      <div class="clearfix table-tools">
        <div class="buttons"></div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">

            <a-form-item>
                <a-radio-group :value="searchData.shsz" @change="handleButChange">
                    <a-radio-button value="sh">
                    主板
                    </a-radio-button>
                    <a-radio-button value="sz">
                    创业
                    </a-radio-button>
                </a-radio-group>
            </a-form-item>


            <a-form-item>
                <a-radio-group :value="searchData.macdfilter">
                    <a-radio-button value="1" @click="handleBut8Change('1')">
                    MACD
                    </a-radio-button>
                </a-radio-group>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>代码</span>
                    </template>
                    <a-input v-model.trim="searchData.stock_code" allowClear placeholder="代码" style="width: 180px"/>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                  <!-- <a-date-picker
                      v-model="searchData.date"
                      @change="handleDateChange"
                      placeholder="选择日期"
                    /> -->
                    <!-- <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/> -->
                    <a-range-picker :ranges="rangesData" style="width: 230px" :disabled-date="disabledDate" allowClear v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
              <a-button icon="download" :loading="downLoading" @click="toExport" type="primary">下载</a-button>
              <!-- <a-button type="primary" icon="reload" @click="updateFs">刷新</a-button> -->
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
          :columns="columns" @change="handleChange" :dataSource="list">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="opt_content" slot-scope="text">
              <div v-html="text"></div>
            </template>
            <template slot="stock_name" slot-scope="text, record , index">
                <span @click.stop v-clipboard:copy="record.stock_code" >{{text}}</span>
                <a-tag color="red" v-for="(data,index) in record.stock_short_tag" :key="index">
                  {{data}}
                </a-tag>
            </template>
            <template slot="stock_code" slot-scope="text, record , index">
                <a-badge :count="record.stock_kick_count" :offset=[15,0] :number-style="{
        backgroundColor: '#fff',
        color: 'red',
        boxShadow: '0 0 0 1px #d9d9d9 inset',
      }">
                  <span @click="clk(record.stock_code,record)">{{text}}</span>
                </a-badge>
            </template>
            <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
            </template>
            <template slot="stock_zhangdiefu_zuixin" slot-scope="text, record , index">
                <span :style="zuixincolor(text,record.stock_zhangdiefu)"> {{ (formatNumber(text)) }}</span>
              </template>
            <template slot="B" slot-scope="text, record , index">
                <span>{{record.stock_bsm.rocket_buy}}</span>
            </template>
            <template slot="stock_bs" slot-scope="text, record , index">
                <span v-if="record.stock_bs" :style="zuixincolor(record.stock_bs.B,record.stock_bs.S)">{{record.stock_bs.B}}/{{record.stock_bs.S}}</span>
            </template>
            <template slot="stock_chengjiaocha" slot-scope="text, record , index">
              <!-- <a-progress :percent="text" size="small" :show-info="false" /> -->
              <span>{{text}}</span>
            </template>
            <template slot="S" slot-scope="text, record , index">
                <span>{{record.stock_bsm.rocket_sell}}</span>
            </template>
            <template slot="stock_bk_name" slot-scope="text, record , index">
                <span>{{text}}</span>
                <!-- <a href="#" style="color:red;">({{record.stock_bk_zhangtingshu}})</a> -->
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>

      <a-modal v-model="editVisible" :title="stock_title" cancelText="取消" okText="刷新" width="1550px" @ok="handleOk" >
        <vue-element-loading :active="loadingP" color="#00cca2" spinner="spinner"/>
          <a-date-picker @change="onFsChange" />
          <ve-histogram v-if="false"
            :data="fs_list_chart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`${fs_list_chart.title}`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['P','A'],
                // axisSite: { right: ['P','A'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>
          <a-row :gutter="[16,8]">
            <a-col :span="2"  v-for="(data,index) in pie" :key="index"  @click="clkFs(data)">
                    <ve-pie  
                        judge-width
                        :data="data"
                        :legend="{
                            show:false,
                            type:'scroll',
                            orient: 'vertical',
                            left:'0%',
                            align:'left',
                            top:'middle',
                            textStyle: {
                                color:'#8C8C8C'
                            },
                        }
                        "
                        height="130px" 
                        :title="{
                            text:`${data.title}`,
                            subtext: `${data.sub_title}`,
                            left: '30%',
                            top: '65%',
                            // x:'center',
                            // y:'top',
                            textStyle: {
                                fontSize: 12.5,
                            },
                            subtextStyle: {
                                fontSize: 12.5,
                                top:'-10'
                            },
                        }" 
                        :extend="{
                            series:{
                                labelLine: {
                                    show: false, //隐藏指示线
                                },
                                label: {
                                    show: false, //隐藏标示文字
                                },
                                radius: data.radius,
                                center: ['50%', '35%'],
                                // label: {
                                //     normal: {
                                //         show: false,
                                //     },
                                //     show:false,
                                //     normal: {
                                //         formatter: params => {
                                //             return (
                                //                 `${params.name} ${params.value} (${params.percent}%)`
                                //             );
                                //         }
                                //     }
                                // }
                            }
                        }">
                    </ve-pie>
                </a-col>
            </a-row>
            <!-- <a-timeline>
                <a-timeline-item :color="msg.color" v-for="(msg,index) in predictMsg.hands_list" :key="index">{{msg.stock_time}} # {{msg.fs_price}} # {{msg.fs_opt}} # {{msg.fs_type}} # {{formatNumber(msg.fs_total)}} # {{msg.fs_hands}} </a-timeline-item>
            </a-timeline> -->

            <ve-histogram
            :data="rsiChart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:'RSI6 CHART',
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['RSI6'],
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

            <ve-histogram 
            :data="predictChart"
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`${predictChart.title}`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['M'],
                axisSite: { right: ['M'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>


            <ve-histogram 
            :data="predictLiuruChart" 
            v-if="false"
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                text:`资金流入统计`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['M'],
                axisSite: { right: ['M'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

            <a-card v-for="(tableData, index) in kick_his_list">
                <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                    :columns="ztcolumns" :dataSource="tableData">
                    <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1)}}</span>
                    </template>
                    <template slot="price" slot-scope="text, record , index">
                    <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
                    </template>
                </a-table>
            </a-card>

            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                :columns="columns2" :dataSource="liuruList">
                <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1)}}</span>
                </template>
                <template slot="price" slot-scope="text, record , index">
                <span :style="color(text)"> {{ (formatNumber(text)) }}</span>
                </template>
            </a-table>

            <template>
            <!-- <a-timeline>
                <a-timeline-item :color="msg.color" v-for="(msg,index) in predictMsg" :key="index">{{msg.stock_date}} {{msg.tm}} {{msg.msg}}</a-timeline-item>
            </a-timeline> -->
        </template>
        <LEmpty v-if="first>0 && predictChart.rows.length == 0"/>
      </a-modal>

      <a-modal v-model="fsVisible" cancelText="取消" okText="刷新" width="1550px">
        <ve-histogram 
            :data="fs_list_chart" 
            :legend="{show:true,bottom:10}"
            judge-width
            :grid="{right: '5%'}"
            :mark-line="markLine"
            :title="{
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }"
            :settings="{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'], 
                showLine: ['P','A'],
                axisSite: { right: ['P','A'] }, 
            }" 
            :extend="histogramChartExtend" ></ve-histogram>

        <!-- <a-row :gutter="[16,16]">
            <a-col :span="4"  v-for="(data,index) in fs_list" :key="index">
                <a-timeline>
                    <a-timeline-item :color="msg.color" v-for="(msg,index) in data" :key="index">{{msg.stock_time}} # {{msg.fs_price}} # {{msg.fs_opt}} # {{formatNumber(msg.fs_total)}} </a-timeline-item>
                </a-timeline>
            </a-col>
        </a-row> -->

      </a-modal>
  </div>
</template>

<script>

const ztcolumns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' ,sorter:()=>{}},
  { title: '涨停时间',  align:'left', dataIndex: 'stock_zhangting_shijian', key: 'stock_zhangting_shijian',sorter:()=>{}, scopedSlots: { customRender: 'stock_zhangting_shijian' }},
  { title: '股票名称',  align:'left', dataIndex: 'stock_name', key: 'stock_name',sorter:()=>{}},
  { title: '股票连板',  align:'left', dataIndex: 'kick_no', key: 'kick_no',sorter:()=>{}},
  { title: '股票代码',  align:'left', dataIndex: 'stock_code', key: 'stock_code',sorter:()=>{}, scopedSlots: { customRender: 'stock_code' }},
  { title: '主力封单',  align:'left', dataIndex: 'stock_fengdan', key: 'stock_fengdan',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '最大封单',  align:'left', dataIndex: 'stock_max_fengdan', key: 'stock_max_fengdan',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '主力净额',  align:'left', dataIndex: 'stock_zhuli_jinge', key: 'stock_zhuli_jinge',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '主力卖出',  align:'left', dataIndex: 'stock_zhuli_maichu', key: 'stock_zhuli_maichu',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '主力买入',  align:'left', dataIndex: 'stock_zhuli_mairu', key: 'stock_zhuli_mairu',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '股票板块', width: 160, align:'left', dataIndex: 'stock_bk', key: 'stock_bk',sorter:()=>{}},
  { title: '涨停原因', width: 160, align:'left', dataIndex: 'stock_reason', key: 'stock_reason',sorter:()=>{}, scopedSlots: { customRender: 'stock_bk_name' }},
]

const columns = [
  { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id'},
  { title: '名称', width: 80, align:'left', dataIndex: 'stock_name', scopedSlots: { customRender: 'stock_name' }, key: 'stock_name'},
  { title: '代码', width: 80, align:'center', dataIndex: 'stock_code', key: 'stock_code',scopedSlots: { customRender: 'stock_code' }},
  { title: '今收', width: 60, align:'center', dataIndex: 'stock_now', key: 'stock_now'},
  { title: '涨幅', width: 90, align:'center', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  // { title: '明收', width: 60, align:'center', dataIndex: 'stock_next', key: 'stock_next', scopedSlots: { customRender: 'price' }},
  // { title: 'B/S', width: 80, align:'center', dataIndex: 'stock_bs', key: 'stock_bs',sorter:()=>{}, scopedSlots: { customRender: 'stock_bs' }},
  // { title: 'L', width: 60, align:'center', dataIndex: 'stock_bs_bili', key: 'stock_bs_bili',sorter:()=>{}},
  { title: '均线', width: 60, align:'center', dataIndex: 'stock_avg_zhangdiefu', key: 'stock_avg_zhangdiefu'},
  // { title: '昨日', width: 80, align:'center', dataIndex: 'stock_yesterday_avg_zhangdiefu', key: 'stock_yesterday_avg_zhangdiefu',sorter:()=>{}, scopedSlots: { customRender: 'price' }},
  { title: '振幅', width: 60, align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu'},
  { title: '5涨速', width: 60, align:'center', dataIndex: 'stocck_5zhangsu', key: 'stocck_5zhangsu'},
  // { title: '价格', width: 80, align:'center', dataIndex: 'stock_now', key: 'stock_now',sorter:()=>{}},
  { title: '换手', width: 60, align:'center', dataIndex: 'stock_huanshou', key: 'stock_huanshou'},
  { title: '量比', width: 60, align:'center', dataIndex: 'stock_liangbi', key: 'stock_liangbi'},
  { title: '流通', align:'center', dataIndex: 'stock_liutongshizhi', key: 'stock_liutongshizhi', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  // { title: '成交比', align:'center', dataIndex: 'stock_chengjiaocha', key: 'stock_chengjiaocha' , scopedSlots: { customRender: 'stock_chengjiaocha' },sorter:()=>{}},
  { title: '今日', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe' , scopedSlots: { customRender: 'price' },sorter:()=>{}},
  // { title: '昨日', align:'center', dataIndex: 'stock_jt_chengjiaoe', key: 'stock_jt_chengjiaoe', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  // { title: '净交额', align:'center', dataIndex: 'stock_sort_chengjiaoe', key: 'stock_sort_chengjiaoe' , scopedSlots: { customRender: 'price' },sorter:()=>{}},
  { title: '流入',  align:'center', dataIndex: 'liuru', key: 'liuru', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  // { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' },sorter:()=>{}},
  // { title: '净比',  align:'center', dataIndex: 'stock_liuru_bili', key: 'stock_liuru_bili',sorter:()=>{}},
  // { title: '净占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' },sorter:()=>{}},

  // { title: 'B', align:'center', dataIndex: 'B', key: 'B', scopedSlots: { customRender: 'B' },sorter:()=>{}},
  // { title: 'S', align:'center', dataIndex: 'S', key: 'S', scopedSlots: { customRender: 'S' },sorter:()=>{}},
  // { title: 'M', align:'center', dataIndex: 'M', key: 'M', scopedSlots: { customRender: 'M' },sorter:()=>{}},

  { title: '超大', align:'center', dataIndex: 'stock_chaoda_liuru', key: 'stock_chaoda_liuru', scopedSlots: { customRender: 'price' }},
  { title: '超比', align:'center', dataIndex: 'stock_chaoda_liuru_zhanbi', key: 'stock_chaoda_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  // { title: '大流', align:'center', dataIndex: 'stock_da_liuru', key: 'stock_da_liuru', scopedSlots: { customRender: 'price' }},
  // { title: '大比',  align:'center', dataIndex: 'stock_da_liuru_zhanbi', key: 'stock_da_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  // { title: '中流', align:'center', dataIndex: 'stock_zhong_liuru', key: 'stock_zhong_liuru', scopedSlots: { customRender: 'price' }},
  // { title: '中比', align:'center', dataIndex: 'stock_zhong_liuru_zhanbi', key: 'stock_zhong_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  // { title: '小流', align:'center', dataIndex: 'stock_xiao_liuru', key: 'stock_xiao_liuru', scopedSlots: { customRender: 'price' }},
  // { title: '小比', align:'center', dataIndex: 'stock_xiao_liuru_zhanbi', key: 'stock_xiao_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: 'MA5', align:'center', dataIndex: 'stock_5avg', key: 'stock_5avg'},
  { title: 'MA10', align:'center', dataIndex: 'stock_10avg', key: 'stock_10avg'},
  { title: 'RSI6', align:'center', dataIndex: 'stock_rsi6', key: 'stock_rsi6'},
  { title: 'RSI6差', align:'center', dataIndex: 'stock_rsi6_cha', key: 'stock_rsi6_cha'},
  { title: '板块', align:'center', dataIndex: 'stock_bk', key: 'stock_bk' , scopedSlots: { customRender: 'stock_bk_name' }},
  { title: 'time', align:'center', dataIndex: 'stock_date', key: 'stock_date',sorter:()=>{}},
  // { title: '涨停理由', align:'center', dataIndex: 'stock_reason', key: 'stock_reason'},
]

const columns2 = [
  // { title: '#', width:'70px', scopedSlots: { customRender: 'index' }, key: 'id' },
  { title: '日期', align:'center', dataIndex: 'stock_date', key: 'stock_date'},
  { title: '开盘', align:'center', dataIndex: 'stock_jinkai', key: 'stock_jinkai'},
  { title: '价格', align:'center', dataIndex: 'stock_now', key: 'stock_now'},
  { title: '振幅', align:'center', dataIndex: 'stock_zhenfu', key: 'stock_zhenfu'},
  { title: '盘口', align:'center', dataIndex: 'stock_pankou', key: 'stock_pankou'},
  { title: '涨幅', align:'left', dataIndex: 'stock_zhangdiefu', key: 'stock_zhangdiefu', scopedSlots: { customRender: 'price' }},
  { title: '成交额', align:'center', dataIndex: 'stock_chengjiaoe', key: 'stock_chengjiaoe' , scopedSlots: { customRender: 'price' }},
  { title: '净流入',  align:'center', dataIndex: 'stock_liuru', key: 'stock_liuru', scopedSlots: { customRender: 'price' }},
  { title: '流入占比', align:'center', dataIndex: 'stock_liuru_zhanbi', key: 'stock_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '超大流入', align:'center', dataIndex: 'stock_chaoda_liuru', key: 'stock_chaoda_liuru', scopedSlots: { customRender: 'price' }},
  { title: '超大占比', align:'center', dataIndex: 'stock_chaoda_liuru_zhanbi', key: 'stock_chaoda_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '大流入', align:'center', dataIndex: 'stock_da_liuru', key: 'stock_da_liuru', scopedSlots: { customRender: 'price' }},
  { title: '大占比',  align:'center', dataIndex: 'stock_da_liuru_zhanbi', key: 'stock_da_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '中流入', align:'center', dataIndex: 'stock_zhong_liuru', key: 'stock_zhong_liuru', scopedSlots: { customRender: 'price' }},
  { title: '中占比', align:'center', dataIndex: 'stock_zhong_liuru_zhanbi', key: 'stock_zhong_liuru_zhanbi', scopedSlots: { customRender: 'price' }},

  { title: '小流入', align:'center', dataIndex: 'stock_xiao_liuru', key: 'stock_xiao_liuru', scopedSlots: { customRender: 'price' }},
  { title: '小占比', align:'center', dataIndex: 'stock_xiao_liuru_zhanbi', key: 'stock_xiao_liuru_zhanbi', scopedSlots: { customRender: 'price' }}

]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'operation',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
  },

  data() {
    this.chartSettings = {
        xAxisType: ['KMB', 'percent'],
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [],
          label:{
              position:'insideStartTop'
          }
      },
      this.tooltip = {
          show:true,  
          trigger: "axis",
          alwaysShowContent:true,
          formatter: function(params) { 
            var res;
            console.log(params)
            return res;
          }
        },
      this.chartStockSettings = {
        yAxis:{
          show:false,
        },
        grid: {
          containLabel: true,
        },
        showDataZoom: false,
        start: 0,
        end: 100,
        showMA: false,
        MA: [5,10],
        legendName: {}, 
        showVol: false,
        upColor: '#FF8787',
        downColor: '#7FB77E',
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [],
          label:{
              show: false, //隐藏标示文字
              position:'insideEndTop'
          }
      },
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
    return {
      loading: false,
      downLoading: false,
      exportLoading:false,
      fsVisible:false,
      first:0,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      stock_types:[
          {filter_name:'zoro'},
          {filter_name:'zoro_avg'},
          {filter_name:'zoro_quiet'},
          {filter_name:'zoro_zhenfu'},
          {filter_name:'zoro_xiao'},
          {filter_name:'zoro_zhong'},
      ],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      fs_list:[],
      fs_list_chart:[],
      kick_his_list:[],
      stock_title:'',
      stock_detail:{},
      columns,
      columns2,
      ztcolumns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      operationTypes: C_ITEMS.operationTypes,
      searchData: {
        shsz:'sh',
        stock_status:1,
        macdfilter:'',
        ztfilter:'',
        lkfilter:'',
        lrfilter:'',
      },
      visible: false,
      editVisible:false,
      refundVisible: false,
      histogramChartExtend:{
        series(item){
            console.log(item)
                if(item){
                    item[0].barMaxWidth = 10
                    item[0].color='#23d9ae'
                    // item[0].itemStyle = Object.assign({}, {
                    //     normal:{
                    //         color: function(params) {
                    //             return {
                    //                 type: 'linear',
                    //                 x:1,
                    //                 y:1,
                    //                 x2:0,
                    //                 y2:0,
                    //                 colorStops: [{
                    //                     offset: 0,
                    //                     color: '#ffffff'
                                        
                    //                 }, {
                    //                     offset: 1,                    
                    //                     color: '#23d9ae'
                    //                 }],
                    //             }
                    //         },
                    //         barBorderRadius:[30,30,0,0]
                    //     }
                    // })


                    if(item[1]){
                      item[1].color='red'
                        // item[1].itemStyle = Object.assign({}, {
                        //     normal:{
                        //         color: function(params) {
                        //             return {
                        //                 type: 'linear',
                        //                 x:1,
                        //                 y:1,
                        //                 x2:0,
                        //                 y2:0,
                        //                 colorStops: [{
                        //                     offset: 0,
                        //                     color: 'red'
                                            
                        //                 }, {
                        //                     offset: 1,                    
                        //                     color: '#23d9ae'
                        //                 }],
                        //             }
                        //         },
                        //         barBorderRadius:[30,30,0,0]
                        //     }
                        // })
                    }

                    if(item[2]){
                      item[2].color='black'
                    }

                    if(item[3]){
                      item[3].color='#FF6600'
                    }
                }
                return item
            },
            xAxis:{
                axisLine:{
                    show:true,
                    lineStyle: {
                        type: 'dotted',
                    }
                },
            },
            yAxis(item){
                item[0].axisLine = Object.assign({}, {
                    show:false
                })
                item[0].splitLine = Object.assign({}, {
                    show:true,
                    lineStyle: {
                        type: 'dotted'
                    }
                })
                return item
            },
        },
        pie:{},
        stockCode:'',
        predictMsg:[],
        stockChartData:[],
        dailyChart:[],
        rsiChart:[],
        predictChart:[],
        predictLiuruChart:[],
        loadingP:false,
        liuruChart:[],
        liuruList:[],
    }
  },
  mixins: [ ranges, tableMixins ],
  created() {
    this.searchData.date =moment().format('YYYY-MM-DD');
    this.searchData.created_at =[moment().subtract('days',0).format('YYYY-MM-DD'),moment().subtract('days',0).format('YYYY-MM-DD')]
  },
  methods: {
    handleDateChange(e){
      this.searchData.date = e.format('YYYY-MM-DD');
      this.getList()
    },
    updateFs(){
      console.log('test');
    },
    handleButChange(e){
        this.searchData.shsz = e.target.value
        this.getList()
    },
    handleBut2Change(e){
        if(this.searchData.ztfilter==e){
            this.searchData.ztfilter = ""
        }else{
            this.searchData.ztfilter = e
        }
        this.getList()
    },
    handleBut8Change(e){
        if(this.searchData.macdfilter==e){
            this.searchData.macdfilter = ""
        }else{
            this.searchData.macdfilter = e
        }
        this.getList()
    },
    handleBut5Change(e){
        if(this.searchData.stock_status==e){
            this.searchData.stock_status = "0"
        }else{
            this.searchData.stock_status = e
        }
        this.getList()
    },
    handleBut3Change(e){
        if(this.searchData.lkfilter==e){
            this.searchData.lkfilter = ""
        }else{
            this.searchData.lkfilter = e
        }
        this.getList()
    },
    handleBut4Change(e){
        if(this.searchData.lrfilter==e){
            this.searchData.lrfilter = ""
        }else{
            this.searchData.lrfilter = e
        }
        this.getList()
    },
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onFsChange(date, dateString) {
        this.paramsData.search.stock_date = dateString
        this.getPredictChart(this.stockCode);
    },
    clkFs(data){
        this.fsVisible = true;
        this.fs_list = data.sub_fs;
        this.fs_list_chart = data.sub_fs_chart;
    },
    closeSearch() {
      this.visible = false
    },
    async handleOk() {
        this.getPredictChart(this.stockCode)  
    },
    clk(stock_code,stock_detail){
      this.stockCode = stock_code;
      this.stock_detail = stock_detail;
      this.getPredictChart(stock_code)
    },
    async getPredictChart(stock_code) {
      this.editVisible = true;
      this.loadingP = true;
      let res = await this.$store.dispatch('enterpriseLabLiuruAction', {data:{search:{stock_name:stock_code,stock_updatetime:this.stock_detail.stock_updatetime}}})
      this.predictChart = res.data.predict_chart
      this.predictLiuruChart = res.data.predict_liuru_chart
      this.pie = res.data.pie
      this.kick_his_list = res.data.kick_his_list
      this.stock_title =  this.stock_detail.stock_name + ' ' + res.data.pie_title
      this.liuruList = res.data.liuru_list
      this.predictMsg = res.data.fs_list
      this.rsiChart = res.data.rsi_chart
      this.fs_list_chart = res.data.sub_fs_chart;
      this.loadingP = false
    },
    zuixincolor(value,target){
        if(Number(value) < Number(target)){
          return {color:'green'};
        }
        if(value==0){
          return '';
        }
        if(Number(value) > Number(target)){
          return {color:'red'};
        }
    },
    color(value){
        if(value < 0){
          return {color:'green'};
        }
        if(value==0){
          return '';
        }
        if(value > 0){
          return {color:'red'};
        }
    },
    formatNumber(value) {
      let flag = '';
      if(value<0){
        value=Math.abs(value)
        flag = '-';
      }
      if (value >= 100000000) {
        return flag+(value / 100000000).toFixed(2) + ' 亿';
      } else if (value >= 10000) {
        return flag+(value / 10000).toFixed(2) + ' 万';
      } else if (value >= 1000) {
        return flag+(value / 1000).toFixed(2) + ' 千';
      } else {
        return flag+value;
      }
    },
    async toExport(){
      this.exportLoading = true
      let obj = {export:'1'}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      if(typeof this.searchParams.search.stock_code === 'string'){
        this.searchParams.search.stock_code = this.searchParams.search.stock_code.trim()
          .split(/\s+/) 
          .filter(item => item !== '')
      }
      let res = await this.$store.dispatch('enterpriseLabStockHisExportAction', { data: this.searchParams })
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `数据.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            console.log(sorter);
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.column.key}`
            }else{
                this.searchParams['sort'] = `-${sorter.column.key}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList()
    },
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      if(typeof this.searchParams.search.stock_code === 'string'){
        this.searchParams.search.stock_code = this.searchParams.search.stock_code.trim()
          .split(/\s+/) 
          .filter(item => item !== '')
      }
      let res = await this.$store.dispatch('enterpriseLabStockHisAction', { data: this.searchParams })
      console.log(this.searchParams);
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-content {
  white-space: normal; /* 允许换行 */
  word-wrap: break-word; /* 长单词换行 */
}
.ant-modal-body .ant-row{
    margin: 10px 20px !important;
}
</style>